import api from '../api';
const COMPANY_API_URL = `${process.env.REACT_APP_CURRENT_COMPANY_API_URL}`;

//Permission Details
const getPermissionDetails = async ({ userId, userRoleId }) => {
  try {
    const response: any = await api.actionHandler({
      url: `${COMPANY_API_URL}/Permissions?searchType=permissions&userId=${userId}&userRoleId=${userRoleId}`,
      method: 'GET'
    });
    return response?.data?.data;
  } catch (error) {
    throw error;
  }
};

export { getPermissionDetails };
