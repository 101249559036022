import * as React from 'react';
import Box from '@mui/material/Box';
import { Divider, Hidden, SwipeableDrawer, Typography, useTheme } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';
import { HeaderContext } from 'src/contexts/HeaderContext';
import { useQuery } from '@tanstack/react-query';
import { getCurrentCompanyDetails } from 'src/api/common/dropdownList';
import axios from 'axios';
import { useTranslate } from 'src/locales';

function ProfileModal({ toggle, setToggle }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const {enqueueSnackbar} = useSnackbar();
  const { profileDetails } = React.useContext(HeaderContext);
  const { t } = useTranslate();
  const handleLogout = (e): void => {
    e.preventDefault();
    localStorage.removeItem('userName');
    localStorage.removeItem('admin-token');
    localStorage.removeItem('userId');
    localStorage.removeItem('userRoleId');
    localStorage.removeItem('parentCustomerId');
    localStorage.removeItem('lastActivity');
    localStorage.removeItem('counterId');
    localStorage.removeItem('workPeriodId');
    delete axios.defaults.headers.common.Authorization;
    window.location.href = '/auth/sign-in';
  };

 //Current Company Details
  const { data: companyDetails, } = useQuery<any>({
    queryKey: ['companyDetails'],
    queryFn: () => getCurrentCompanyDetails(),
    refetchOnWindowFocus: false,
    enabled: toggle === true ? true : false
  });

  return (
    <>
      {/* @ts-ignore */}
      <SwipeableDrawer
        ModalProps={{
          onBackdropClick: () => setToggle(false)
        }}
        anchor="right"
        open={toggle}
        sx={{
          '& .MuiDrawer-paper': {
            width: '26%',
            zIndex: 9999,
            [theme.breakpoints.down('sm')]: {
              width: '100%'
            },
            //for mobile
            '@media (max-width: 600px)': {
              width: '100%'
            },
            //for tablet
            '@media (min-width: 600px) and (max-width: 960px)': {
              width: '70%'
            },
            borderRadius: '0 0 0 1rem',
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
            height: '100%',
            backgroundColor: '#fff',
          }
        }}
        onClose={() => setToggle(false)}
      >
        <Box sx={{px:3,py:2.5}}>
          <Box sx={{display:'flex', justifyContent:'space-between',alignItems:'top'}}>
            <Box sx={{display:'flex',gap:2,alignItems:'center'}}>
              <img style={{borderRadius:'4px'}} width={50} height={50} src={'https://d9-wret.s3.us-west-2.amazonaws.com/assets/palladium/production/s3fs-public/styles/full_width/public/thumbnails/image/Placeholder_person.png?itok=w8HA7eHV'}/>
              <Box>
                <Typography variant='h5'>{profileDetails?.customerName||'-'}</Typography>
                <Typography variant='body1' sx={{mt:.5,color:'gray',fontSize:12}}>{profileDetails?.email||'-'}</Typography>
              </Box>
            </Box>
            <Box>
              <CloseIcon sx={{cursor:'pointer',color:'red'}} onClick={() => setToggle(false)}/>
            </Box>
          </Box>
          <Typography variant='body1' sx={{mt:.5,color:'gray',fontSize:12}}>{t("Profile.UserID")} : {profileDetails?.userName||'-'}</Typography>
          <Hidden lgUp>
          <Typography variant='body1' color='primary' sx={{mt:.5,fontSize:12.5}}>{t("Profile.Company")} : {companyDetails?.companyName||'-'}</Typography>
          </Hidden>
        </Box>
          <Divider/>
          <Box sx={{px:3,py:1.1, display:'flex',alignItems:'center',justifyContent:'space-between'}}>
          <Typography variant='body1' sx={{fontSize:14}}>{t("Profile.Account")}</Typography>
            <Box>
              <Box sx={{display:'flex',alignItems:'center',cursor:'pointer',justifyContent:'space-between',gap:1}} onClick={handleLogout}>
                <LogoutIcon sx={{fontSize:18,color:'red'}}/>
                <Typography variant='body1' sx={{fontSize:14,color:'red'}}>{t("Profile.SignOut")}</Typography>
              </Box>
            </Box>
          </Box>
          <Divider/>
          <Box sx={{px:3,py:1.1}}>
            <Typography variant='body1' sx={{mt:.5,fontSize:14}}>{t("Profile.PlanName")} : {profileDetails?.planName||'-'}</Typography>
          </Box>
          <Divider/>
          {Number(profileDetails?.expiryWithInDays) >0&&<><Box sx={{px:3,py:1.1}}>
            <Typography variant='body1' sx={{fontSize:14}}>{t("Profile.PlanExpiresIn")} {profileDetails?.expiryWithInDays||0} {t("Profile.days")}</Typography>
          </Box>
          <Divider/>
          </>}

          <Box sx={{px:3,py:1.1, display:'flex',alignItems:'center',justifyContent:'space-between'}}>
          <Typography variant='body1' sx={{fontSize:14}}>Additional Users : {profileDetails?.license||0}</Typography>
            {profileDetails?.licenseCharge!==0&&<Box>
              <Box sx={{display:'flex',alignItems:'center',cursor:'pointer',justifyContent:'space-between',gap:1}}>
                <AddIcon sx={{fontSize:18}}/>
                <Typography variant='body1' sx={{fontSize:14}} 
                onClick={(e) => {
                  e.preventDefault();
                  if(profileDetails?.isTrialPlan||profileDetails?.isExpired ){
                    enqueueSnackbar(t("Profile.YouCantAddMoreLicense"), { variant: 'error' });
                    return;
                  }
                  navigate('/add-payment')}}>Add More User</Typography>
              </Box>
            </Box>}
          </Box>
          <Divider/>
          {/* <Collapse in={open} timeout="auto" unmountOnExit sx={{px:2,py:1.1}}>
            <Box sx={{border:'1px solid #F1F1F1',borderRadius:'8px',p:1}}>
              <Table>
                <TableBody>
                <TableRow sx={{border:'1px solid #F1F1F1' }}>
                    <TableCell sx={{ fontWeight: '600', }} colSpan={1} align='left'>Additional Amt</TableCell>
                    <TableCell sx={{ fontWeight: '600', }} colSpan={1} align='right'>{licenseCharge||0}</TableCell>
                  </TableRow>
                  <TableRow sx={{border:'1px solid #F1F1F1' }}>
                    <TableCell sx={{ fontWeight: '600', }} colSpan={1} align='left'>Additional Users</TableCell>
                    <TableCell sx={{ fontWeight: '600', }} colSpan={1} align='right'>
                    <TextField
                      sx={{ width:'50%'}}
                      type="number"
                      size="small"
                      placeholder="No of users"
                      value={additionalUsers}
                      onChange={(e)=>setAdditionalUsers(e.target.value)}
                    />
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{border:'1px solid #F1F1F1' }}>
                    <TableCell sx={{ fontWeight: '600', }} colSpan={1} align='left'>Gross Amount</TableCell>
                    <TableCell sx={{ fontWeight: '600', }} colSpan={1} align='right'>{calculateTotalAmount()||0}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 2,gap:1}}>
                <Button variant="contained" color='error' size='small' onClick={() => setOpen(false)}>
                  Cancel
                </Button>
                <Button variant="contained" size='small' onClick={() => navigate('/add-payment')}>
                  Add
                </Button>
              </Box>
            </Box>
          </Collapse> */}
      </SwipeableDrawer>
    </>
  );
}

export default React.memo(ProfileModal);
