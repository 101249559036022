import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import 'nprogress/nprogress.css';
import App from 'src/App';
import { closeSnackbar, SnackbarProvider } from 'notistack';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
// React Query
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { IconButton } from '@mui/material';

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false
     },
    mutations: {
      retry: false,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));  

root.render(
  // Provide the client to your App
  <QueryClientProvider client={queryClient}>
    <HelmetProvider>
      <SnackbarProvider
        autoHideDuration={3000}
        maxSnack={2}
        preventDuplicate
        action={(snackbarId) => (
          <IconButton size='small' color="inherit" onClick={() => closeSnackbar(snackbarId)}>
            <HighlightOffIcon sx={{ fontSize: 20 }} />
          </IconButton>
        )}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <BrowserRouter basename="/">
          <App />
        </BrowserRouter>
      </SnackbarProvider>
    </HelmetProvider>
  </QueryClientProvider>
);
