import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import { useBoolean } from 'src/hooks/common/use-boolean';
import { useResponsive } from 'src/hooks/common/use-responsive';

import { useSettingsContext } from 'src/contexts/SettingContext';

import Main from './main';
import Header from './header';
import NavMini from './mini-navbar';
import NavVertical from './vertical-navbar';
import { Outlet, useLocation, useNavigate } from 'react-router';
import { decryptData } from 'src/hooks/common/useEncryption';
import { useQuery } from '@tanstack/react-query';
import { getUserSubscriptionDetails } from 'src/api/subscriptions/subscriptions';
import { useContext, useEffect } from 'react';
import { HeaderContext } from 'src/contexts/HeaderContext';
import { Alert, CircularProgress, Typography } from '@mui/material';
import Footer from 'src/components/Footer';
import { getCurrentCompanyDetails } from 'src/api/common/dropdownList';

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const settings = useSettingsContext();
  const { profileDetails, setProfileDetails } = useContext(HeaderContext);
  const location = useLocation();
  const navigate = useNavigate();

  //Login User Details
  const loginUserId = decryptData('userId');

  //Current Company Details
  const { data: companyDetails } = useQuery<any>({
    queryKey: ['companyDetails'],
    queryFn: () => getCurrentCompanyDetails(),
    refetchOnWindowFocus: false,
  });

  const { data: userSubscriptionDetails, isLoading } = useQuery<any>({
    queryKey: ['userSubscriptionDetails', loginUserId],
    queryFn: () => getUserSubscriptionDetails(loginUserId),
    refetchOnWindowFocus: false,
    enabled: !!loginUserId
  });

  useEffect(() => {
    if (userSubscriptionDetails) {
      setProfileDetails(userSubscriptionDetails);
    }
  }, [userSubscriptionDetails]);

  const isNotSpecialPath = !['/pos', '/subscriptions', '/add-payment'].includes(location.pathname);

  const alertMessages = [
    {
      condition: userSubscriptionDetails?.isExpired,
      severity: 'error',
      message: 'Your Subscription has expired.',
      action: `${userSubscriptionDetails?.isRenewalPlan ? 'Renew Now' : 'Subscribe Now'}`,
    },
    {
      condition: !userSubscriptionDetails?.isExpired && !userSubscriptionDetails?.isTrialPlan && userSubscriptionDetails?.isGoingtoExpire && userSubscriptionDetails?.isRenewalPlan,
      severity: 'info',
      message: `Your subscription will expire within ${userSubscriptionDetails?.expiryWithInDays} days. Please renew your subscription before the expiry date.`,
      action: 'Renew Now',
    },
    {
      condition: !userSubscriptionDetails?.isExpired && userSubscriptionDetails?.isTrialPlan && userSubscriptionDetails?.isGoingtoExpire,
      severity: 'warning',
      message: `Act fast! Your free trial period of MySale Books will expire in ${userSubscriptionDetails?.expiryWithInDays} days.`,
      action: 'Subscribe Now',
    },
  ];

  const lgUp = useResponsive('up', 'lg');

  const nav = useBoolean();

  //@ts-ignore
  const isMini = settings.themeLayout === 'mini';

  const renderNavMini = <NavMini />;

  const renderNavVertical = <NavVertical openNav={nav.value} onCloseNav={nav.onFalse} />;

  if (isMini) {
    return (
      <>
        {isNotSpecialPath && <Header onOpenNav={nav.onTrue} companyName={companyDetails?.companyName} />}

        <Box
          sx={(!isLoading && profileDetails) && {
            display: 'flex',
            flexDirection: { xs: 'column', lg: 'row' },
          }}
        >
          {isNotSpecialPath && (lgUp ? renderNavMini : renderNavVertical)}
          
          {(isLoading && !profileDetails) ? <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '75vh' }}>
            <CircularProgress sx={{ width: 60, height: 60 }} />
          </Box> : <Main>
            {alertMessages?.map(
              ({ condition, severity, message, action }) =>
                condition && location.pathname !== '/subscriptions' && (
                  <Box sx={{ mb: 1 }} key={severity}>
                    <Alert variant="outlined" severity={severity as any} sx={{ backgroundColor: '#fff' }}>
                      <Typography variant="h6" sx={{ mb: 1, fontWeight: '700' }}>
                        {message}
                      </Typography>
                      <Box onClick={() => { navigate('/subscriptions', { state: { from: 'dashboard', encrypted: true } }) }}>
                        <Typography variant="h6" color="primary" sx={{ cursor: 'pointer' }}>
                          {action}
                        </Typography>
                      </Box>
                    </Alert>
                  </Box>
                )
            )}
            <Outlet />
          </Main>
          }
        </Box>
      </>
    );
  };

  return (
    <>
      {isNotSpecialPath && <Header onOpenNav={nav.onTrue} companyName={companyDetails?.companyName} />}

      <Box
        sx={(!isLoading && profileDetails) && {
          display: 'flex',
          flexDirection: { xs: 'column', lg: 'row' },
        }}
      >
        {isNotSpecialPath && renderNavVertical}

        {(isLoading && !profileDetails) ? <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress sx={{ width: 60, height: 60 }} />
        </Box> : <Main>
          {alertMessages?.map(
            ({ condition, severity, message, action }) =>
              condition && location.pathname !== '/subscriptions' && (
                <Box sx={{ mb: 1 }} key={severity}>
                  <Alert variant="outlined" severity={severity as any} sx={{ backgroundColor: '#fff' }}>
                    <Typography variant="h6" sx={{ mb: 1, fontWeight: '700' }}>
                      {message}
                    </Typography>
                    <Box onClick={() => { navigate('/subscriptions', { state: { from: 'dashboard', encrypted: true } }) }}>
                      <Typography variant="h6" color="primary" sx={{ cursor: 'pointer' }}>
                        {action}
                      </Typography>
                    </Box>
                  </Alert>
                </Box>
              )
          )}
          <Outlet />
          {location.pathname === '/dashboard' && (
            <Box>
              <Footer />
            </Box>
          )}
        </Main>
        
        }
      </Box>
    </>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node,
};
